// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation";

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "JmDev",
  title: "Hi all, I'm Jovan",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 who loves solving real-world problems through code. An enthusiast of cycling, motorcycling, and addicted to mechanics."
  ),
  resumeLink: "/documents/CV_JovanMilojkovic.pdf", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/JovanMilojkovic",
  linkedin: "https://www.linkedin.com/in/jovan-milojkovic87/",
  gmail: "jovan.milojkovic87@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Building dynamic web applications and creating responsive websites"
    ),
    emoji("⚡ Integrating with databases like PostgreSQL and MongoDB "),
    emoji(
      "⚡ Leveraging third-party services like Netlify and Railway to extend application functionality and streamline development workflows."
    )
  ],

  softwareSkills: [
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Node.Js",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "OpenAPI",
      fontAwesomeClassname: "devicon-openapi-plain"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "Spring",
      fontAwesomeClassname: "devicon-spring-original"
    },
    {
      skillName: "Hibernate",
      fontAwesomeClassname: "devicon-hibernate-plain"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "devicon-mongodb-plain"
    },
    {
      skillName: "Mongoose",
      fontAwesomeClassname: "devicon-mongoose-original"
    },
    {
      skillName: "PostgreSQL",
      fontAwesomeClassname: "devicon-postgresql-plain"
    },
    {
      skillName: "Junit5",
      fontAwesomeClassname: "devicon-junit-plain"
    },
    {
      skillName: "Jest",
      fontAwesomeClassname: "devicon-jest-plain"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "devicon-git-plain"
    },
    {
      skillName: "GitHub",
      fontAwesomeClassname: "devicon-github-original"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "devicon-docker-plain"
    },
    {
      skillName: "VS Code",
      fontAwesomeClassname: "devicon-vscode-plain"
    },
    {
      skillName: "IntelliJ",
      fontAwesomeClassname: "devicon-intellij-plain"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Codecool Vienna",
      logo: require("./assets/images/codecool.png"),
      subHeader: "Full Stack Software Developer",
      duration: "May 2022 - August 2023",
      desc: "Intensive education that provides hands-on training in multiple programming languages, coupled with theoretical knowledge and agile methodologies.",
      descBullets: [
        "Approximately 80 solo projects completed.",
        "Approximately 20 team projects completed."
      ]
    },
    {
      schoolName: "University of Vienna",
      logo: require("./assets/images/University_of_Vienna_logo.png"),
      subHeader: "Business Informatics",
      duration: "January 2016 - January 2018 (not completed)"
    },
    {
      schoolName: "University of Belgrade",
      logo: require("./assets/images/UniversityOfBelgrad.png"),
      subHeader: "Bachelor of International Business Management ",
      duration: "September 2006 - December 2010"
    }
  ]
};

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "HTML / CSS / React",
      progressPercentage: "70%"
    },
    {
      Stack: "JavaScript / Java / Spring / ",
      progressPercentage: "75%"
    },
    {
      Stack: "Git / VS Code / IntelliJ",
      progressPercentage: "75%"
    }
  ],
  displayCodersrank: false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Full Stack Developer",
      company: "Codecool",
      companylogo: require("./assets/images/codecool.png"),
      date: "May 2022 – Aug 2023",
      descBullets: [
        "Software Development",
        "Advanced Algorithms",
        " Testing",
        "Scrum"
      ]
    },
    {
      role: "Customer Service",
      company: "Game Zone AG",
      companylogo: require("./assets/images//CASHPOINT-Logo.png"),
      date: "May 2017 – Jun 2023",
      descBullets: [
        "Support and advising for existing clients",
        "Ensuring compliance with legal regulations.",
        "Operating the betting cash register."
      ]
    },
    {
      role: "Sales Employee",
      company: "WinWin Computer Shop",
      companylogo: require("./assets/images/winwin_logo.png"),
      date: "Jan 2015 – Jan 2016",
      descBullets: [
        "Personal assistance to customers and recommending appropriate products",
        "Successfully met and exceeded sales targets.",
        "Collaborated with colleagues to optimize store operations"
      ]
    },
    {
      role: "Credit Officer",
      company: "BNB Paribas",
      companylogo: require("./assets/images/Findomestic.png"),
      date: "May 2015 – Jun 2015",
      descBullets: [
        "Conducting comprehensive credit analysis on loan applications",
        "Preparing credit proposals and loan underwriting documents",
        "Ensuring compliance with regulatory guidelines and internal policies"
      ]
    }
  ]
};

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects

const projects = {
  title: "Projects",
  subtitle:
    "Some solo and team projects that I completed in the last year. More projects are coming soon 🤓",
  projects: [
    {
      image: require("./assets/images/tic-tac-toe.png"),
      projectName: "Tic Tac Toe",
      projectDesc:
        "A timeless classic that never loses its charm and remains endlessly entertaining",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://magical-seahorse-032798.netlify.app/"
        },
        {
          name: "Source Code",
          url: "https://github.com/JovanMilojkovic/Tic-Tac-Toe"
        }
      ]
    },
    {
      image: require("./assets/images/Bizz-Link-Screen Capture.jpeg"),
      projectName: "BizLink",
      projectDesc: "A modern way of sharing contact information with others.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://elegant-stroopwafel-9ff4ad.netlify.app/"
        },
        {
          name: "Source Code",
          url: "https://github.com/CodecoolGlobal/bizz-link"
        }
      ]
    },
    {
      image: require("./assets/images/Tripico.png"),
      projectName: "Trip&Co",
      projectDesc:
        "Trip&Co is an adventure text game that takes you on a journey through Europe. ",
      footerLink: [
        {
          name: "Source Code",
          url: "https://github.com/CodecoolGlobal/mighty-text-adventure-java"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section

const achievementSection = {
  title: emoji("Certificate 🏆 and Mentor opinion "),
  achievementsCards: [
    {
      image: require("./assets/images/CodecoolCertificate.png"),
      imageAlt: "Codecool Certificate"
    },
    {
      image: require("./assets/images/montorOpinion.png"),
      imageAlt: "Mentor opinion"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Whether you're interested in discussing projects or just want to say hi, my inbox is always open to all. Feel free to reach out 😉",
  email_address: "jovan.milojkovic87@gmail.com"
};

const isHireable = true;

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  projects,
  achievementSection,
  contactInfo,
  isHireable
};
