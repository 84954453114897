import React from "react";
import "./Button.scss";

export default function Button({text, className, href, newTab, download}) {
  // Function to handle button click
  const handleClick = event => {
    // If download is true, prevent default action (navigation) and initiate download action
    if (download) {
      event.preventDefault(); // Prevent default navigation behavior
      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to the file URL
      link.href = href;

      // Set the download attribute to suggest a filename for the downloaded file
      link.setAttribute("download", href);

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Clean up by removing the link from the document body
      document.body.removeChild(link);
    }
  };
  return (
    <div className={className}>
      <a
        className="main-button"
        onClick={handleClick}
        href={href}
        target={newTab && "_blank"}
      >
        {text}
      </a>
    </div>
  );
}
