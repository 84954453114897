import {StrictMode, React} from "react";
import "./App.scss";
import Main from "./containers/Main";

function App() {
  return (
    <StrictMode>
      <Main />
    </StrictMode>
  );
}

export default App;
